import React from 'react'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Generic
import BasicPage from '../components/shared/BasicPage/BasicPage'

const TermsAndConditionsPage = (props) => {
  return (
    <>
      <Seo
        title='Privacy and Cookies'
        slug={props.location.pathname}
      />
      <BasicPage>
        <h1 className='h2 text-center mb-12'>Privacy and Cookies</h1>
        <div className='c-prose'>
          <p>Effective date: March 8th, 2018</p>
          <p>Northcoders TechEd Limited ("us", "we", or "our") operates the https://northcodersteched.com/ website (the "Service").</p>
          <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
          <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://northcodersteched.com/</p>
          <h2><strong>Definitions</strong></h2>
          <h3><strong>Personal Data</strong></h3>
          <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
          <h3><strong>Usage Data</strong></h3>
          <p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          <h3><strong>Cookies</strong></h3>
          <p>Cookies are small pieces of data stored on a User’s device.</p>
          <h3><strong>Data Controller</strong></h3>
          <p>Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
          <p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
          <h3><strong>Data Processor (or Service Providers)</strong></h3>
          <p>Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
          <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
          <h3><strong>Data Subject</strong></h3>
          <p>Data Subject is any living individual who is the subject of Personal Data.</p>
          <h3><strong>User</strong></h3>
          <p>The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
          <h2><strong>Information Collection And Use</strong></h2>
          <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
          <h3><strong>Types of Data Collected</strong></h3>
          <h4><strong><em>Personal Data</em></strong></h4>
          <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Cookies and Usage Data</p>
            </li>
          </ul>
          <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
          <p>All our website financial transactions are handled through our payment services provider, Stripe. You can review the provider's privacy policy at https://stripe.com/gb/privacy. We will share information with our payment services provider only to the extent necessary for the purposes of processing payments you make via our website, refunding such payments and dealing with complaints and queries relating to such payments and refunds.</p>
          <h4><strong><em>Usage Data</em></strong></h4>
          <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <h4><strong><em>Tracking Cookies Data</em></strong></h4>
          <p><strong>ABOUT COOKIES</strong></p>
          <p>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
          <p>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
          <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
          <p>Cookies can be used by web servers to identify and track users as they navigate different pages on a website and identify users returning to a website.</p>
          <p><strong>OUR COOKIES</strong></p>
          <p>We may use session and persistent cookies on our website, a list of which we will provide on request at hello@northcoders.com.</p>
          <p><strong>BLOCKING COOKIES</strong></p>
          <p>Most browsers allow you to refuse to accept cookies; for example:</p>
          <p>in Internet Explorer (version 11), you can block cookies using the cookie handling override settings available by clicking "Tools", "Internet Options", "Privacy" and then "Advanced";</p>
          <p>in Firefox (version 39), you can block all cookies by clicking "Tools", "Options", "Privacy", selecting "Use custom settings for history" from the drop-down menu, and unticking "Accept cookies from sites"; and</p>
          <p>in Chrome (version 44), you can block all cookies by accessing the "Customise and control" menu, and clicking "Settings", "Show advanced settings" and "Content settings", and then selecting "Block sites from setting any data" under the "Cookies" heading.</p>
          <p>Blocking all cookies will have a negative impact upon the usability of many websites.</p>
          <p>If you block cookies, you will not be able to use all the features on our website.</p>
          <p><strong>DELETING COOKIES</strong></p>
          <p>You can delete cookies already stored on your computer; for example:</p>
          <p>in Internet Explorer (version 11), you must manually delete cookie files (you can find instructions for doing so at http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);</p>
          <p>in Firefox (version 39), you can delete cookies by clicking "Tools", "Options" and "Privacy", then selecting "Use custom settings for history" from the drop-down menu, clicking "Show Cookies", and then clicking "Remove All Cookies"; and</p>
          <p>in Chrome (version 44), you can delete all cookies by accessing the "Customise and control" menu, and clicking "Settings", "Show advanced settings" and "Clear browsing data", and then selecting "Cookies and other site and plug-in data" before clicking "Clear browsing data"</p>
          <p>Deleting cookies will have a negative impact on the usability of many websites.</p>
          <p><strong>COOKIE PREFERENCES</strong></p>
          <p>You can manage your preferences relating to the use of cookies on our website by emailing us at hello@northcodersteched.com.</p>
          <h2><strong>Use of Data</strong></h2>
          <p>Northcoders TechEd Limited uses the collected data for various purposes:</p>
          <ul>
            <li>
              <p>To provide and maintain our Service</p>
            </li>
            <li>
              <p>To notify you about changes to our Service</p>
            </li>
            <li>
              <p>To allow you to participate in interactive features of our Service when you choose to do so</p>
            </li>
            <li>
              <p>To personalise our website to you</p>
            </li>
            <li>
              <p>To send you non-marketing commercial communications</p>
            </li>
            <li>
              <p>To send you email notifications that you have specifically requested</p>
            </li>
            <li>
              <p>To send you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter);</p>
            </li>
            <li>
              <p>To send you marketing communications relating to our business which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications);</p>
            </li>
            <li>
              <p>To provide customer support</p>
            </li>
            <li>
              <p>To provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information)</p>
            </li>
            <li>
              <p>To gather analysis or valuable information so that we can improve our Service</p>
            </li>
            <li>
              <p>To monitor the usage of our Service</p>
            </li>
            <li>
              <p>To detect, prevent and address technical issues</p>
            </li>
            <li>
              <p>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</p>
            </li>
          </ul>
          <h2><strong>Retention of Data</strong></h2>
          <p>Northcoders TechEd Limited will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p>Northcoders TechEd Limited will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
          <h2><strong>Transfer Of Data</strong></h2>
          <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
          <p>If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Kingdom and process it there.</p>
          <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
          <p>Northcoders TechEd Limited will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
          <h2><strong>Disclosure Of Data</strong></h2>
          <p>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this policy.</p>
          <p>We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</p>
          <p>We will not, without your express consent, supply your personal information to any third party for the purpose of their or any other third party's direct marketing.</p>
          <p>Except as provided in this policy, we will not provide your personal information to third parties.</p>
          <h3><strong>Business Transaction</strong></h3>
          <p>If Northcoders TechEd Limited is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
          <h3><strong>Disclosure for Law Enforcement</strong></h3>
          <p>Under certain circumstances, Northcoders TechEd Limited may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h3><strong>Legal Requirements</strong></h3>
          <p>Northcoders TechEd Limited may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>
              <p>To comply with a legal obligation</p>
            </li>
            <li>
              <p>To protect and defend the rights or property of Northcoders TechEd Limited</p>
            </li>
            <li>
              <p>To prevent or investigate possible wrongdoing in connection with the Service</p>
            </li>
            <li>
              <p>To protect the personal safety of users of the Service or the public</p>
            </li>
            <li>
              <p>To protect against legal liability</p>
            </li>
          </ul>
          <h2><strong>Security Of Data</strong></h2>
          <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
          <h2><strong>Your Rights</strong></h2>
          <p>Northcoders TechEd Limited aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
          <p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
          <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
          <p>In certain circumstances, you have the right:</p>
          <ul>
            <li>
              <p>To access and receive a copy of the Personal Data we hold about you</p>
            </li>
            <li>
              <p>To rectify any Personal Data held about you that is inaccurate</p>
            </li>
            <li>
              <p>To request the deletion of Personal Data held about you</p>
            </li>
          </ul>
          <p>You have the right to data portability for the information you provide to Northcoders TechEd Limited. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.</p>
          <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
          <h2><strong>Service Providers</strong></h2>
          <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
          <h3><strong>Analytics</strong></h3>
          <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
          <p><strong>ANALYTICS COOKIES</strong></p>
          <p>We use Google Analytics to analyse the use of our website.</p>
          <p>Our analytics service provider generates statistical and other information about website use by means of cookies.</p>
          <p>The analytics cookies used by our website have the following names: _ga, _gat, __utma, __utmt, __utmb, __utmc, __utmz and __utmv.</p>
          <p>The information generated relating to our website is used to create reports about the use of our website.</p>
          <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
          <p>Our analytics service provider's privacy policy is available at: http://www.google.com/policies/privacy/.</p>
          <p><strong>THIRD PARTY COOKIES</strong></p>
          <p>Our website also uses third party cookies, a list of which we will provide on request at hello@northcodersteched.com.</p>
          <h3><strong>Behavioral Remarketing</strong></h3>
          <p>Northcoders TechEd Limited uses remarketing services to advertise on third party websites to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
          <p><strong>Google AdWords</strong></p>
          <p>Google AdWords remarketing service is provided by Google Inc.</p>
          <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href='http://www.google.com/settings/ads'>http://www.google.com/settings/ads</a></p>
          <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
          <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href='http://www.google.com/intl/en/policies/privacy/'>http://www.google.com/intl/en/policies/privacy/</a></p>
          <p><strong>Twitter</strong></p>
          <p>Twitter remarketing service is provided by Twitter Inc.</p>
          <p>You can opt-out from Twitter's interest-based ads by following their instructions: <a href='https://support.twitter.com/articles/20170405'>https://support.twitter.com/articles/20170405</a></p>
          <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <a href='https://twitter.com/privacy'>https://twitter.com/privacy</a></p>
          <p><strong>Facebook</strong></p>
          <p>Facebook remarketing service is provided by Facebook Inc.</p>
          <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href='https://www.facebook.com/help/164968693837950'>https://www.facebook.com/help/164968693837950</a></p>
          <p>To opt-out from Facebook's interest-based ads follow these instructions from Facebook: <a href='https://www.facebook.com/help/568137493302217'>https://www.facebook.com/help/568137493302217</a></p>
          <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href='http://www.aboutads.info/choices/'>http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href='http://youradchoices.ca/'>http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href='http://www.youronlinechoices.eu/'>http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>
          <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href='https://www.facebook.com/privacy/explanation'>https://www.facebook.com/privacy/explanation</a></p>
          <p><strong>AdRoll</strong></p>
          <p>AdRoll remarketing service is provided by Semantic Sugar, Inc.</p>
          <p>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page: <a href='http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false'>http://info.evidon.com/pub_info/573?v=1nt=1nw=false</a></p>
          <p>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page: <a href='http://www.adroll.com/about/privacy'>http://www.adroll.com/about/privacy</a></p>
          <h2><strong>Links To Other Sites</strong></h2>
          <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
          <h2><strong>Children's Privacy</strong></h2>
          <p>Our Service does not address anyone under the age of 13 ("Children").</p>
          <p>We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
          <h2><strong>Changes To This Privacy Policy</strong></h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          <h2><strong>Contact Us</strong></h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul>
            <li>
              <p>By email: privacy@northcoders.com</p>
            </li>
            <li>
              <p>By visiting this page on our website: </p>
              <a href='https://northcodersteched.com/contact-us'>https://northcodersteched.com/contact-us</a>
            </li>
          </ul>
          <p><strong>DATA PROTECTION REGISTRATION</strong></p>
          <p>We are registered as a data controller with the UK Information Commissioner's Office.</p>
          <p><strong>OUR DETAILS</strong></p>
          <p>This website is owned and operated by Northcoders TechEd Limited.</p>
          <p>We are registered in England and Wales under registration number 09912193 and our registered office is at 6th Floor, Blackfriars House, Parsonage, Manchester M3 2JA.</p>
          <p>Our principal place of business is at Manchester Technology Centre, 103 Oxford Rd, Manchester M1 7ED.</p>
          <p>You can contact us by writing to the business address given above, by using our website contact form, by email to hello@northcoders.com or by telephone on 0333 050 4368.</p>
        </div>
      </BasicPage>
    </>
  )
}

export default TermsAndConditionsPage
